body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html {
    overflow: -moz-scrollbars-vertical; 
    overflow-y: scroll;
}

.MuiListItemIcon-root {
  min-width: 30px !important;
}

.rotate-90 {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

.highlight_header {
  font-weight: bold;
  color: white; 
  background-color: #1A5276;
}

.color_good {
  font-weight: bold;
  color: white; 
  background-color: #059700;
}
.color_switching {
  font-weight: bold;
  color: white; 
  background-color: #002BBD;
}
.color_bad {
  font-weight: bold;
  color: white; 
  background-color: #E10000;
}
.color_nonoperational {
  font-weight: bold;
  color: white; 
  background-color: #808080;
}
.color_inactive {
  font-weight: bold;
  color: white; 
  background-color: #000000;
}



.ARINC_header {
  color: #154360;
}

.EMAIL_header {
  color: #633974;
}

.FLIGHTAWARE_header {
  color: #154360;
}

.PAC_header {
  color: #633974;
}

.FLIFO_header {
  color: #154360;
}

.MQ_header {
  color: #633974;
}


